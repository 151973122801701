import photo1K from '../Images/kyivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvNzAzNDkvYnJhbmNoX2ltYWdlc19CYkRxakhoZC5qcGc.png';
import photo2K from '../Images/kyivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvNzAzNjAvYnJhbmNoX2ltYWdlc18zNloxSm5qTi5qcGc.png';
import photo3K from '../Images/kyivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvNzAzNjEvYnJhbmNoX2ltYWdlc19EQUtXNnRmVi5qcGc.png';
import photo4K from '../Images/kyivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvNzAzNTAvYnJhbmNoX2ltYWdlc182cVhNaXlsUy5qcGc.png';
import photo5K from '../Images/kyivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvNzAzNTcvYnJhbmNoX2ltYWdlc19WYW1xOFFFWC5qcGc.png';
import photo6K from '../Images/kyivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvNzAzNTEvYnJhbmNoX2ltYWdlc19MV1lieTZRby5qcGc.png';
import photo7K from '../Images/kyivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvNzAzNTgvYnJhbmNoX2ltYWdlc19QaXBPVm03bS5qcGc.png';
import photo8K from '../Images/kyivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvNzAzNTIvYnJhbmNoX2ltYWdlc19yVmtYZHdJOS5qcGc.png';
import photo9K from '../Images/kyivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvNzAzNTkvYnJhbmNoX2ltYWdlc19seDM5clZPVC5qcGc.png';
import photo10K from '../Images/kyivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvNzAzNTMvYnJhbmNoX2ltYWdlc18zcWZ2czFQMS5qcGc.png';
import photo11K from '../Images/kyivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvNzAzNTQvYnJhbmNoX2ltYWdlc19DeWUxZEY3Vi5qcGc.png';
import photo12K from '../Images/kyivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvNzAzNTUvYnJhbmNoX2ltYWdlc19BVWRXU2NCQy5qcGc.png';
import photo13K from '../Images/kyivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvNzAzNTYvYnJhbmNoX2ltYWdlc185b3ZOY3NTWC5qcGc.png';

import photo1L from '../Images/lvivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvMjc5MDcvYnJhbmNoX2ltYWdlc19HbTZEbGNmdi5qcGc.png';
import photo2L from '../Images/lvivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvMjc5MDgvYnJhbmNoX2ltYWdlc19sQVdFZ1JyMi5qcGc.png';
import photo3L from '../Images/lvivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvMjc5MDkvYnJhbmNoX2ltYWdlc181V1VMTlFNNy5qcGc.png';
import photo4L from '../Images/lvivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvMjc5MDQvYnJhbmNoX2ltYWdlc19WRmhqakd4TS5qcGc.png';
import photo5L from '../Images/lvivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvMjc5MDUvYnJhbmNoX2ltYWdlc19tZ1YyTlVEOC5qcGc.png';
import photo6L from '../Images/lvivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvMjc5MDYvYnJhbmNoX2ltYWdlc19YdWpkSVpoMC5qcGc.png';
import photo7L from '../Images/lvivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvMjc5MTAvYnJhbmNoX2ltYWdlc19DWTJhQXcxWC5qcGc.png';
import photo8L from '../Images/lvivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvMjg0MDcvYnJhbmNoX2ltYWdlc19ZZGhmS3FZUy5qcGc.png';
import photo9L from '../Images/lvivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvMjg0MDgvYnJhbmNoX2ltYWdlc19aV01hTnZKUi5qcGc.png';
import photo10L from '../Images/lvivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvMjg0MDkvYnJhbmNoX2ltYWdlc19CaDJaZkdyYy5qcGc.png';
import photo11L from '../Images/lvivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvMjg0MDQvYnJhbmNoX2ltYWdlc19JNFZUTFpQRC5qcGc.png';
import photo12L from '../Images/lvivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvMjg0MDUvYnJhbmNoX2ltYWdlc19MMld2QUlDNS5qcGc.png';
import photo13L from '../Images/lvivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvMjg0MDYvYnJhbmNoX2ltYWdlc19rVUlNZ0Q2UC5qcGc.png';
import photo14L from '../Images/lvivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvMjg0MTAvYnJhbmNoX2ltYWdlc19tZW92c2FLbi5qcGc.png';
import photo15L from '../Images/lvivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvMjg0MTEvYnJhbmNoX2ltYWdlc19WTW5EbmV6NS5qcGc.png';
import photo16L from '../Images/lvivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvMjg0MTIvYnJhbmNoX2ltYWdlc19ibzVIWm9PcC5qcGc.png';
import photo17L from '../Images/lvivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvMjg0MTMvYnJhbmNoX2ltYWdlc19wSGREeDExYS5qcGc.png';
import photo18L from '../Images/lvivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvMjg0MTQvYnJhbmNoX2ltYWdlc19ZWTF2VW1OZi5qcGc.png';
import photo19L from '../Images/lvivska/aHR0cHM6Ly9zdG9yYWdlLmVhc3l3ZWVrLmlvL2xpdmUvbWVkaWEvMjg0MTUvYnJhbmNoX2ltYWdlc19nNXJsdmFGSi5qcGc.png';

import user from '../Images/simple-user-icon.png';


const kyivskaImages = [photo1K, photo2K, photo3K, photo4K, photo5K, photo6K, photo7K, photo8K, photo9K, photo10K, photo11K, photo12K, photo13K];
const lvivskaImages = [photo1L, photo2L, photo3L, photo4L, photo5L, photo6L, photo7L, photo8L, photo9L, photo10L, photo11L, photo12L, photo13L, photo14L, photo15L, photo16L, photo17L, photo18L, photo19L];

export const images =  {
    '35070': kyivskaImages,
    '16266': lvivskaImages,
}

export {
    user
}