import { CContainer, CRow, CCol, CButton } from '@coreui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './404.css';

const Page404 = () => {
    const navigate = useNavigate();
    const handleClick = (e) => {
        navigate(`/`);
    }
    return (
        <>
            <CContainer className="404-page main-content mt-5">
                <CRow className="mt-5">
                    <CCol xs={12} className="mb-4 text-center">
                        <h1 className='tg-text'>Помилка 404</h1>
                        <h2 className='tg-text mb-3'>Вибачте, такої сторінки не існує</h2>
                        <CButton color="dark" size="lg" style={{maxWidth: 166}} className="w-100 tg-border-reverce tg-text-reverce tg-background-reverce" onClick={handleClick}>
                            На головну
                        </CButton>
                    </CCol>
                </CRow>
            </CContainer>
        </>
    );
};

export default Page404;